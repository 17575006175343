@import '~@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-pro/scss/regular.scss';
@import '~@fortawesome/fontawesome-pro/scss/solid.scss'; 

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Growers Edge Colors
$mat-gef: (
  50: #e3f2fd,
  100: rgba(0,108,180,.2),
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #006cb4, //Growers Edge Blue
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$harvest-history-manager-primary: mat-palette($mat-gef, 800);
$harvest-history-manager-accent: mat-palette($mat-light-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$harvest-history-manager-warn: mat-palette($mat-red, 600);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$harvest-history-manager-theme: mat-light-theme((
  color: (
    primary: $harvest-history-manager-primary,
    accent: $harvest-history-manager-accent,
    warn: $harvest-history-manager-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($harvest-history-manager-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%;  background-color: #f4f5f0;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
header {
  display: flex;
  padding: 0 0 2.5rem 0;
}

$primary: map-get($harvest-history-manager-theme, primary);
$warn: map-get($harvest-history-manager-theme, warn);
.mat-typography {
  h2 {
    margin-bottom: 3rem;
  }
}
.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}

.no-border {
  border: none !important;
}

.no-padding {
  padding: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}

.hide {
  display: none !important;
}

.pull-right {
  float:right;
}

.sub-text {
  display: block;
  font-size: 9pt;
  color: #6c7a89;
}

a.mat-button {
  text-align: left;
  padding: 0;

  .mat-icon {
    padding-top: 5px;
    font-size: 1rem;
    text-align: center;
  }
}

.material-icons {
  font-size: 20px;
}

.container {
  box-sizing: border-box;
  max-width: 1190px;
  padding: 40px;
  margin: 0 auto;
}

header.small-toolbar{
  background: mat-color($primary, lighter);
  border-radius: 0.3rem 0.3rem 0 0;
  padding: 0.5rem 2rem;
}

section.sub-container {
  padding: 2rem;
  border: 1px solid mat-color($primary, lighter);
  border-radius: 0 0 0.3rem 0.3rem;

  .sub-container {
    border: none;
    margin-top: 0.5rem;
    h2 {
      border-bottom: 2px solid mat-color($primary, lighter);
    }
  }

  .arrowed-sub {
    border-radius: 0.3rem;
    margin: 2rem 0 40px 40px;
    box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, .10);
    padding: 20px;
    border: 1px solid rgba(0,108,180,.2);
    position: relative;
    &::before {
      font-family: 'Material Icons';
      content: '';
      color: mat-color($primary);
      font-weight: 900;
      position: absolute;
      left: -35px;
      top: 18px;
      display: inline-block;
      font-size: 18px;
    }
    
  }
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 .15rem .25rem rgba(0,0,0,.1)!important;
  border: 1px solid rgba(0,108,180,.2);
  border-radius: 0.3rem !important;
}

a:hover {
  cursor: pointer;
}

.form-wrapper {
  padding: 2rem;
  background: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .10);
  border-radius: 0.3rem;
  min-height: 100%;

  .button-wrapper {
    display: block;
    width: 100%;
    text-align: left;
    padding-top: 2rem;

    button {
      margin: 0 5px 0 0;
      border-radius: 0.3rem;

      .fas {
        margin-right: 5px;
      }

      &.mat-flat-button.mat-primary[disabled] {
        background: url(/assets/img/loading-animation.gif) no-repeat left mat-color($primary);
        border: 1px solid mat-color($primary, darker);
        background-position: 12px;
        padding-left: 38px;
        color:  1px solid mat-color($primary, darker);
        opacity: 1;
        cursor: not-allowed;

        .fas {
          display: none;
        }
      }

      &.mat-button {
        border: 1px solid;
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;

    &.wrap {
      flex: wrap;
    }

    &.vertical {
      flex-direction: column;
      justify-content: start;
    }

    &.full {
      width: 100%;
    }

    &.half {
      width: 49.5%;
      vertical-align: text-top;
      padding-right: 20px;
    }

    .column {
      width: 48%;
      padding-bottom: 0;
    }

    .mat-form-field {
      width: 100%;

      .mat-form-field-required-marker {
        color: mat-color($warn);
      }

      .mat-form-field-underline {
        background-color: mat-color($primary);
      }

      label {
        color: mat-color($primary);
      }

      input {
        width: 100%;
      }
    }

    .mat-form-field-disabled .mat-form-field-underline {
      background-image: linear-gradient(90deg,rgba(0,0,0,.42) 0,rgba(0,0,0,.42) 33%,transparent 0);
      background-size: 4px 100%;
      background-repeat: repeat-x;
      background-position: 0;
      background-color: transparent;
    }

    .mat-radio-group {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 20px;
      display: flex;
    }
  }
}

.mat-table {
  mat-row {
    &.selected {
      background-color: #dcd6f7;
    }
    .mat-cell {
      padding: 0 0 0 20px;

      .mat-icon {
        color: mat-color($primary);
        font-size: 24px;
      }
    }
  }

  .mat-header-cell, .mat-cell, .mat-footer-cell {
    padding: 10px 0 0 20px;

    &.mat-column-select {
      word-wrap: break-word !important;
      white-space: unset !important;
      flex: 0 0 50px !important;
      width: 50px !important;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .align-right {
      width: 100%;
      text-align: right;
    }
  }
  .mat-header-cell {
    text-transform: uppercase;
    color: mat-color($primary);
    background-color: rgba(0, 108, 180, 0.2);
  }
  .required::after {
    content: '  *';
    color: mat-color($warn);
  }

  .mat-form-field {
    width: 95%;

    .mat-form-field-underline {
      background-color: mat-color($primary);
    }
  }
  mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border-bottom-color: rgba(0, 108, 180, 0.2);
  }
}

.spacer {
  flex: 1;
}

.mat-menu-panel {
  .fas {
    color: mat-color($primary);
    margin-right: 16px;
    font-size: 16px;
  }
}

.none-wrapper {
  width: auto;
  display: inline-block;
  position: relative;
  top: -40px;
  padding-left: 20px;
}
.mat-expansion-panel-header {
  padding: 0 2rem !important;
}
.mat-expansion-panel-body {
  padding: 0 2rem 2rem !important;
}

.mat-action-row {
  padding: 1rem 2rem !important;
}

.mat-action-row {
  border-top-color: rgba(0, 108, 180, 0.2);
}

.mat-tab-header {
  margin-bottom: 2rem;
  border-bottom: none !important;
}

.mat-tab-labels {
  background-color: rgba(0, 108, 180, 0.2);
  padding-left: 20px;
  padding-top: 0.5rem;
}

.mat-tab-label {
    &.mat-tab-label-active {
      background: #FFFFFF;
      border: none;
      opacity: 1;
    }
}

.mat-ink-bar {
  height: 0 !important;
}

.search-wrapper {
  padding: 20px 20px 0;
  background-color: rgba(0, 108, 180, 0.2);
  margin-bottom: -20px;

  .mat-form-field-flex {
    background: #FFFFFF;
  }

  .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    box-shadow: none !important;
    border-radius: 0 !important;
    height: 39px;
  }

}

.breadcrumbs a {
  color: mat-color($primary);
  text-decoration: underline;
}

mat-header-row {
  border-bottom: none !important;
}

mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
  padding-right: 24px !important;
}

.breadcrumbs {
  padding: 0 0 1rem 0;

  a {
    color: mat-color($primary);
  }
}

.inlined-section {
  h3 {
    padding-top: 1rem;
  }
}

.mat-menu-item {
  .mat-icon {
    margin-right: 8px !important;
  }
}
